import React, { useEffect, useState, useContext, useRef } from 'react';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import Layout from '../../shared/Layout';
// import eye from '../../../assets/eye.svg';
import {
  customBaseUrl,
  httpGet,
  httpPost,
  httpPostFormData,
} from '../../../action/http';
import { hideLoader, showLoader } from '../../../utils/loader';
import { ProfileContext } from '../../../store/context/ProfileContext';
import { Button } from 'antd';
import PinModal from '../../shared/Modals/PinModal';
import Swal from 'sweetalert2';
import swal from 'sweetalert';
import { ArrowBack } from '@material-ui/icons';
import RequestHome from './request';
import AccountListModal from './selectAcountModal';
// import Delete from '../../../assets/image/delete.svg';
export default function LoanRequest(props) {
  const [activities, setActivities] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [viewMore, setViewMore] = useState(false);
  const [otp, setOtp] = useState('');
  const [loanType, setLoanType] = useState('pof');
  const [showOtpModal, setShowOtpModal] = useState(false);
  const [tab, setTab] = useState(1);
  const { userDetails } = useContext(ProfileContext);
  const [data, setData] = useState([]);
  const inputRef = useRef(null);
  const [file, setFile] = useState(null);
  const [file2, setFile2] = useState(null);
  const [file3, setFile3] = useState(null);
  const [sessionId, setSessionId] = useState('');
  const [showLoan, setShowLoan] = useState(true);
  const [actList, setActList] = useState([]);
  const [showActOpt, setShowActOpt] = useState(false);
  const [verificationMethod, setVerificationMethod] = useState({});
  const [terms, setTerms] = useState();

  let today = new Date();
  let dd = today.getDate();
  let mm = today.getMonth() + 1; //January is 0!
  const yyyy = today.getFullYear();

  if (dd < 10) {
    dd = '0' + dd;
  }

  if (mm < 10) {
    mm = '0' + mm;
  }
  today = yyyy + '-' + mm + '-' + dd;
  const handleUploadClick = () => {
    // Trigger the input field's click function when the button is clicked
    inputRef.current?.click();
  };
  const handleFileChange = async (e, type) => {
    if (!e.target.files) return;
    showLoader();
    let newForm = new FormData();
    newForm.append('file', e.target.files[0]);
    newForm.append('fileName', type);
    newForm.append('userId', userDetails.userId);
    const resp = await httpPostFormData(
      `/upload/others`,
      newForm,
      customBaseUrl.fileResourseUrl
    );
    if (type === 'statement' && resp.status) {
      setData({ ...data, bankStatement: resp.data });
      setFile(e.target.files[0]);
    }
    if (type === 'ggId' && resp.status) {
      setData({ ...data, ggId: resp.data });
      setFile3(e.target.files[0]);
    }
    if (type === 'govtId' && resp.status) {
      setData({ ...data, govtId: resp.data });
      setFile2(e.target.files[0]);
    }
    hideLoader();
    // Perform file upload logic here
  };

  const getLogs = async () => {
    showLoader();
    const res = await httpGet(
      `${customBaseUrl.logUrl}/api/v1/log/${userDetails.userId}`
    );
    if (res?.status) {
      // console.log('>>>', res.data);
      setActivities(res.data);
    }
    hideLoader();
  };
  const handleSubmit = async () => {
    if (!data.bvn) {
      setTab(1);
      Swal.fire('Oops!', 'BVN can not be empty');
      return;
    }
    showLoader();
    const pData = {
      bvn: data.bvn,
      scope: 'bank_accounts',
    };
    const res = await httpPost(
      '/api/v1/loan/initiate/bvn/look-up',
      pData,
      customBaseUrl.loanUrl
    );
    if (res.status) {
      hideLoader();
      setActList(res?.data?.methods)
      setShowActOpt(true);
      setSessionId(res.data?.session_id);
    } else {
      hideLoader();
      Swal.fire('Oops!', res.message);
      console.log({ res });
    }
  };
  const handleGetDetails = async () => {
    const pData = {
      method: verificationMethod?.method,
      phone_number: verificationMethod?.phone_number,
    };
    // const res = await httpPost(
    //   `/api/v1/loan/fetch/bvn/details?bvn=${data.bvn}&sessionId=${e.session_id}`,
    //   pData,
    //   customBaseUrl.loanUrl
    // );
    showLoader();
    const res = await httpPost(
      `/api/v1/loan/verify/bvn/otp?sessionId=${sessionId}`,
      pData,
      customBaseUrl.loanUrl
    );
    if (res.status) {
      setShowOtpModal(true);
      // setSessionId(e.session_id);
      // handleSubmitLoan();
      hideLoader();
    } else {
      console.log({ res });
      hideLoader();
    }
  };
  const handleOtp = async (e) => {
    const pData = {
      otp,
    };
    showLoader();
    const res = await httpPost(
      `/api/v1/loan/fetch/bvn/details?bvn=${data.bvn}&sessionId=${sessionId}`,
      pData,
      customBaseUrl.loanUrl
    );
    const actNo = [];
    if (res.status) {
      hideLoader();
      res.data.map((e) => {
        actNo.push({
          bankAccountNumber: e?.account_number,
          bankName: e?.institution.name,
        });
      });
      setShowOtpModal(false);
      setShowActOpt(false);
      setData({ ...data, activeBankAccountsDtos: actNo });
    } else {
      hideLoader();
      swal('Oops!', res.message);
      console.log({ res });
    }
  };
  const handleSubmitLoan = async (e) => {
    e.preventDefault();
    if (!terms) {
      Swal.fire('Oops!', 'Please Agree to Terms & conditions', 'error');
      return;
    }
    if (!data.repayAccount)
      return swal('Oops!', 'Account number must be selected!');
    const ddata = {
      address: data?.address,
      bankStatement: data?.bankStatement,
      bvn: data?.bvn,
      companyAddress: data.companyAddress,
      companyName: data.companyName,
      dateOfBirth: data?.dateOfBirth,
      emailAddress: data?.emailAddress,
      employerFullAddress: data.employerFullAddress,
      employerName: data.employerName,
      firstName: data?.firstName,
      gender: data?.gender,
      govtId: data?.govtId,
      guarantorDetailsDtos: [
        {
          address: data?.ggaddress,
          bvn: data.gBvn,
          dateOfBirth: data?.gdateOfBirth,
          emailAddress: data?.gemailAddress,
          firstName: data?.gfirstName,
          gender: data?.ggender,
          govtId: data?.ggId,
          lastName: data?.glastName,
          middleName: data?.gmiddleName,
          nin: data?.gNin,
          phoneNumber: data?.gphoneNumber,
        },
      ],
      hrManagerEmailAddress: data?.hrManagerEmailAddress,
      hrManagerFullNames: data.hrManagerFullNames,
      hrManagerPhoneNumber: data.hrManagerPhoneNumber,
      lastName: data.lastName,
      lineOfBusiness: data?.occupationType,
      loanTerm: data.loanTerm,
      managerEmailAddress: data.managerEmailAddress,
      managerFullName: data.managerFullName,
      managerPhoneNumber: data.managerPhoneNumber,
      middleName: data?.middleName,
      nin: data?.nin,
      occupation: data?.occupation,
      occupationType: data?.occupationType,
      paySlipDto: {
        doc1: data.bankStatement,
      },
      phoneNumber: data?.phoneNumber,
      requestAmount: data?.requestAmount,
      salaryAmount: data?.salaryAmount,
      typeOfLoan: loanType,
      whenNeeded: data.whenNeeded,
      whenYouJoinedCurrentPlace: data.whenYouJoinedCurrentPlace,
      repaymentAccountDto: {
        accountNumber: data.repayAccount,
        bankName: data.repayBank,
      },
    };
    const res = await httpPost(
      '/api/v1/loan/book-loan',
      ddata,
      customBaseUrl.loanUrl
    );
    if (res?.status) {
      hideLoader();
      setShowLoan(true);
      swal(res?.message);
    } else {
      hideLoader();
      swal('Oops!', res?.message);
      console.log(res);
    }
  };
  const jumpToTab = (tabToGo) => {
    setTab(tabToGo);
  };
  useEffect(() => {
    //  console.log({ userDetails });
    const {
      firstName,
      lastName,
      dateOfBirth,
      gender,
      email,
      //  phoneNumber,
      address,
    } = userDetails;
    setData({
      ...data,
      address,
      firstName,
      lastName,
      dateOfBirth,
      gender,
      email,
      //  phoneNumber,
    });
  }, [userDetails]);
  useEffect(() => {
    if (tab !== 6) return;
    handleSubmit();
  }, [tab]);
  const firstFileOnlyFilter = (file, index) => index === 0;
  return showLoan ? (
    <RequestHome showLoan={showLoan} setShowLoan={setShowLoan} />
  ) : (
    <Layout route={props} currentPage="loan-request">
      <ArrowBack onClick={() => setShowLoan(true)} />
      <div className="mx-2">
        <div
          style={{ display: 'flex', flexDirection: 'row' }}
          className="d-flex justify-content-between shadow my-2 px-2 mt-5"
        >
          <div onClick={() => setTab(1)}>
            <h6 className={`${tab === 1 ? 'text-primary' : ''}`}>
              General Infomation
            </h6>
          </div>
          <div onClick={() => setTab(2)}>
            <h6 className={`${tab === 2 || tab === 3 ? 'text-primary' : ''}`}>
              Type of Loan
            </h6>
          </div>
          <div onClick={() => setTab(4)}>
            <h6 className={`${tab === 4 ? 'text-primary' : ''}`}>
              Upload Documents
            </h6>
          </div>
          <div onClick={() => setTab(5)}>
            <h6 className={`${tab === 5 ? 'text-primary' : ''}`}>
              Guarantors Details
            </h6>
          </div>
          <div onClick={() => setTab(6)}>
            <h6 className={`${tab === 6 ? 'text-primary' : ''}`}>
              Bank Details
            </h6>
          </div>
        </div>
        {tab === 1 ? (
          <div className="col-6">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                setTab(2);
              }}
            >
              <label> Request Loan</label>
              <div className="inputbox-with-one-input-log border rounded mt-5">
                <label>First Name</label>
                <input
                  placeholder="First Name"
                  type="text"
                  required
                  value={data.firstName}
                  onChange={(e) =>
                    setData({ ...data, firstName: e.target.value })
                  }
                />
              </div>
              <div className="inputbox-with-one-input-log rounded border">
                <label>Middle Name</label>
                <input
                  placeholder="Middle Name"
                  type="text"
                  value={data.middleName}
                  onChange={(e) =>
                    setData({ ...data, middleName: e.target.value })
                  }
                />
              </div>
              <div className="inputbox-with-one-input-log rounded border">
                <label>Last Name</label>
                <input
                  placeholder="Middle Name"
                  type="text"
                  required
                  value={data.lastName}
                  onChange={(e) =>
                    setData({ ...data, lastName: e.target.value })
                  }
                />
              </div>
              <div className="inputbox-with-one-input-log rounded border">
                <label>Date of Birth</label>
                <input
                  type="date"
                  required
                  value={data.dateOfBirth}
                  onChange={(e) =>
                    setData({ ...data, dateOfBirth: e.target.value })
                  }
                />
              </div>
              <div className="inputbox-with-one-input-log rounded border mt-5">
                <label>Gender</label>
                <select
                  value={data.gender}
                  onChange={(e) => setData({ ...data, gender: e.target.value })}
                >
                  <option>select gender</option>
                  <option value="MALE">Male</option>
                  <option value="FEMALE">Female</option>
                </select>
              </div>
              <div className="inputbox-with-one-input-log rounded border">
                <label>Address</label>
                <input
                  placeholder="Address"
                  type="text"
                  required
                  value={data.address}
                  onChange={(e) =>
                    setData({ ...data, address: e.target.value })
                  }
                />
              </div>
              <div className="inputbox-with-one-input-log rounded border">
                <label>Email Address</label>
                <input
                  placeholder="First Name"
                  type="email"
                  required
                  value={data.email}
                  onChange={(e) =>
                    setData({ ...data, emailAddress: e.target.value })
                  }
                />
              </div>
              <div className="inputbox-with-one-input-log rounded border">
                <label>BVN</label>
                <NumberFormat
                  placeholder="bvn"
                  format="############"
                  required
                  value={data.bvn}
                  onChange={(e) => setData({ ...data, bvn: e.target.value })}
                />
                {/* <input
                  placeholder="bvn"
                  type="number"
                  required
                  value={data.bvn}
                  onChange={(e) => setData({ ...data, bvn: e.target.value })}
                /> */}
              </div>
              <div className="inputbox-with-one-input-log rounded border">
                <label>Phone Number Linked with BVN</label>
                <NumberFormat
                  format="###########"
                  allowEmptyFormatting
                  required
                  value={data.phoneNumber}
                  onChange={(e) =>
                    setData({ ...data, phoneNumber: e.target.value })
                  }
                />
                {/* <input
                  placeholder="Phone Number"
                  type="text"
                  required
                  value={data.phoneNumber}
                  onChange={(e) =>
                    setData({ ...data, phoneNumber: e.target.value })
                  }
                /> */}
              </div>
              <button className="btn btn-primary mb-5" type="submit">
                Next
              </button>
            </form>
          </div>
        ) : tab === 2 ? (
          <div className="col-6">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                setTab(3);
              }}
            >
              <div className="inputbox-with-one-input-log ">
                <div>
                  <h3>Type of Loan</h3>
                </div>
                <p>Select the type of loan you want to take</p>
              </div>
              <div
                onChange={(e) => setLoanType(e.target.value)}
                className="inputbox-with-one-input-log text-start rounded grid-cols-2 border"
              >
                <div className="flex d-flex align-items-center justify-content-between py-3 px-5">
                  <h6>POF (Proof of Funds)</h6>
                  <input
                    type="radio"
                    value="pof"
                    name="loantype"
                    checked={loanType === 'pof'}
                  />
                </div>
                <hr />
                <h6>
                  Proof of funds (POF) is evidence that you have enough
                  financial resources
                </h6>
              </div>
              <div
                onChange={(e) => setLoanType(e.target.value)}
                className="inputbox-with-one-input-log text-start grid-cols-2 rounded border"
              >
                <div className="flex d-flex align-items-center justify-content-between py-3 px-5">
                  <h6>Personal Loan</h6>
                  <input
                    type="radio"
                    value="personal"
                    name="loantype"
                    checked={loanType === 'personal'}
                  />
                </div>
                <hr />
                <h6>
                  Personal loan provide lump sum of money for various needs,
                  like consolidating debt or covering expenses.
                </h6>
              </div>
              <div className="inputbox-with-one-input-log align-middle text-start grid-cols-2 border rounded">
                <div className="flex d-flex align-items-center justify-content-between py-3 px-5">
                  <h6 className="mt-1">Business Loan</h6>
                  <input
                    onClick={(e) => setLoanType(e.target.value)}
                    type="radio"
                    value="business"
                    name="loantype"
                    checked={loanType === 'business'}
                    className="mt-1"
                  />
                </div>
                <hr />
                <h6>
                  Business loan fuels growth and innovation for entrepreneurs
                </h6>
              </div>
              <button className="btn btn-primary mb-5" type="submit">
                Next
              </button>
            </form>
          </div>
        ) : tab === 3 && loanType === 'pof' ? (
          <div className="col-6">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                setTab(4);
              }}
            >
              <div className="inputbox-with-one-input-log">
                <h2> POF Loan</h2>
              </div>
              <div className="inputbox-with-one-input-log text-start grid-cols-2rounded border">
                <h6> Request Amount</h6>
                <NumberFormat
                  placeholder="Amount you want to loan"
                  thousandSeparator
                  decimalScale="2"
                  fixedDecimalScale
                  onChange={(e) =>
                    setData({ ...data, requestAmount: e.target.value })
                  }
                />
                {/* <input
                  placeholder="Amount you want to loan"
                  type="number"
                  required
                  value={data.requestAmount}
                  step={0.01}
                  onChange={(e) =>
                    setData({ ...data, requestAmount: e.target.value })
                  }
                /> */}
              </div>
              <div className="inputbox-with-one-input-log text-start grid-cols-2 border rounded">
                <h6> When do you need it</h6>
                <input
                  placeholder="Amount you want to loan"
                  type="date"
                  required
                  min={today}
                  value={data?.whenNeeded}
                  onChange={(e) =>
                    setData({ ...data, whenNeeded: e.target.value })
                  }
                />
              </div>
              <button className="btn btn-primary mb-5" type="submit">
                Next
              </button>
            </form>
          </div>
        ) : tab === 3 && loanType === 'personal' ? (
          <div className="col-6">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                setTab(4);
              }}
            >
              <div className="inputbox-with-one-input-log border">
                <label> Personal Loan</label>
              </div>
              <div className="border px-2">
                <label>Loan Details</label>
                <div className="inputbox-with-one-input-log text-start grid-cols-2 border">
                  <h6> Request Amount</h6>
                  <input
                    placeholder="Amount you want to loan"
                    type="number"
                    required
                    value={data.requestAmount}
                    onChange={(e) =>
                      setData({ ...data, requestAmount: e.target.value })
                    }
                  />
                </div>
                <div className="inputbox-with-one-input-log rounded border mt-5">
                  <label>Loan Term</label>
                  <select
                    value={data.loanTerm}
                    onChange={(e) =>
                      setData({ ...data, loanTerm: e.target.value })
                    }
                  >
                    <option>select loan term</option>
                    <option value="3 months">3 Months</option>
                    <option value="4 months">4 Months</option>
                    <option value="5 months">5 Months</option>
                    <option value="6 months">6 Months</option>
                    <option value="7 months">7 Months</option>
                    <option value="8 months">8 Months</option>
                    <option value="9 months">9 Months</option>
                    <option value="10 months">10 Months</option>
                    <option value="11 months">11 Months</option>
                    <option value="12 months">12 Months</option>
                  </select>
                </div>
                <div className="inputbox-with-one-input-log text-start grid-cols-2 border">
                  <h6> When do you need it</h6>
                  <input
                    type="date"
                    required
                    min={today}
                    value={data?.whenNeeded}
                    onChange={(e) =>
                      setData({ ...data, whenNeeded: e.target.value })
                    }
                  />
                </div>
              </div>
              <div className="border mt-3">
                <label>Occupation Details</label>
                <div className="inputbox-with-one-input-log text-start grid-cols-2 border">
                  <h6> Occupation</h6>
                  <input
                    placeholder="Occupation"
                    type="text"
                    required
                    value={data?.occupation}
                    onChange={(e) =>
                      setData({ ...data, occupation: e.target.value })
                    }
                  />
                </div>
                <div className="inputbox-with-one-input-log text-start grid-cols-2 border">
                  <h6> Occupation type</h6>
                  <input
                    placeholder="Occupation type"
                    type="text"
                    required
                    value={data?.occupationType}
                    onChange={(e) =>
                      setData({ ...data, occupationType: e.target.value })
                    }
                  />
                </div>
              </div>
              <div className="border mt-3 px-2">
                <label>Workplace Details</label>
                <div className="inputbox-with-one-input-log text-start grid-cols-2 border">
                  <h6> Company Name</h6>
                  <input
                    placeholder="Company name"
                    type="text"
                    required
                    value={data?.companyName}
                    onChange={(e) =>
                      setData({ ...data, companyName: e.target.value })
                    }
                  />
                </div>
                <div className="inputbox-with-one-input-log text-start grid-cols-2 border">
                  <h6> Company Address</h6>
                  <input
                    placeholder="Company address"
                    type="text"
                    required
                    value={data?.companyAddress}
                    onChange={(e) =>
                      setData({ ...data, companyAddress: e.target.value })
                    }
                  />
                </div>
                <div className="inputbox-with-one-input-log text-start grid-cols-2 border">
                  <h6> Employer Name</h6>
                  <input
                    placeholder="Employer name"
                    type="text"
                    required
                    value={data?.employerName}
                    onChange={(e) =>
                      setData({ ...data, employerName: e.target.value })
                    }
                  />
                </div>
                <div className="inputbox-with-one-input-log text-start grid-cols-2 border">
                  <h6> Employer Address</h6>
                  <input
                    placeholder="Employer name"
                    type="text"
                    required
                    value={data?.employerFullAddress}
                    onChange={(e) =>
                      setData({ ...data, employerFullAddress: e.target.value })
                    }
                  />
                </div>
                <div className="inputbox-with-one-input-log text-start grid-cols-2 border">
                  <h6> Manager Name</h6>
                  <input
                    placeholder="Employer name"
                    type="text"
                    required
                    value={data?.managerFullName}
                    onChange={(e) =>
                      setData({ ...data, managerFullName: e.target.value })
                    }
                  />
                </div>
                <div className="inputbox-with-one-input-log text-start grid-cols-2 border">
                  <h6> Manager Email</h6>
                  <input
                    placeholder="Manager Email"
                    type="email"
                    required
                    value={data?.managerEmailAddress}
                    onChange={(e) =>
                      setData({ ...data, managerEmailAddress: e.target.value })
                    }
                  />
                </div>
                <div className="inputbox-with-one-input-log text-start grid-cols-2 border">
                  <h6> Manager Phone Number</h6>
                  <NumberFormat
                    placeholder="Phone Number"
                    format="+234###########"
                    allowEmptyFormatting
                    required
                    value={data.managerPhoneNumber}
                    onChange={(e) =>
                      setData({ ...data, managerPhoneNumber: e.target.value })
                    }
                  />
                  {/* <input
                    placeholder="Manager Phone"
                    type="text"
                    required
                    value={data?.managerPhoneNumber}
                    onChange={(e) =>
                      setData({ ...data, managerPhoneNumber: e.target.value })
                    }
                  /> */}
                </div>
                <div className="inputbox-with-one-input-log text-start grid-cols-2 border">
                  <h6> HR Name</h6>
                  <input
                    placeholder="HR name"
                    type="text"
                    required
                    value={data?.hrManagerFullNames}
                    onChange={(e) =>
                      setData({ ...data, hrManagerFullNames: e.target.value })
                    }
                  />
                </div>
                <div className="inputbox-with-one-input-log text-start grid-cols-2 border">
                  <h6> HR Email</h6>
                  <input
                    placeholder="HR Email"
                    type="email"
                    required
                    value={data?.hrManagerEmailAddress}
                    onChange={(e) =>
                      setData({
                        ...data,
                        hrManagerEmailAddress: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="inputbox-with-one-input-log text-start grid-cols-2 border">
                  <h6> HR Phone Number</h6>
                  <NumberFormat
                    format="+234###########"
                    allowEmptyFormatting
                    required
                    value={data.hrManagerPhoneNumber}
                    onChange={(e) =>
                      setData({ ...data, hrManagerPhoneNumber: e.target.value })
                    }
                  />
                  {/* <input
                    placeholder="Manager Phone"
                    type="text"
                    required
                    value={data?.hrManagerPhoneNumber}
                    onChange={(e) =>
                      setData({ ...data, hrManagerPhoneNumber: e.target.value })
                    }
                  /> */}
                </div>
                <div className="inputbox-with-one-input-log text-start grid-cols-2 border">
                  <h6> Employment start date</h6>
                  <input
                    type="date"
                    required
                    value={data?.whenYouJoinedCurrentPlace}
                    onChange={(e) =>
                      setData({
                        ...data,
                        whenYouJoinedCurrentPlace: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="inputbox-with-one-input-log text-start grid-cols-2 border">
                  <h6> Salary Range</h6>
                  <input
                    type="text"
                    required
                    value={data?.salaryAmount}
                    onChange={(e) =>
                      setData({ ...data, salaryAmount: e.target.value })
                    }
                  />
                </div>
              </div>
              <button className="btn btn-primary mb-5" type="submit">
                Next
              </button>
            </form>
          </div>
        ) : tab === 3 && loanType === 'business' ? (
          <div className="col-6">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                setTab(4);
              }}
            >
              <div className="inputbox-with-one-input-log border">
                <label> Business Loan</label>
              </div>
              <div className="border px-2">
                <label>Loan Details</label>
                <div className="inputbox-with-one-input-log text-start mt-2 border">
                  <h6> Request Amount</h6>
                  <input
                    placeholder="Amount you want to loan"
                    type="number"
                    required
                    value={data.requestAmount}
                    onChange={(e) =>
                      setData({ ...data, requestAmount: e.target.value })
                    }
                  />
                </div>
                <div className="inputbox-with-one-input-log text-start grid-cols-2 border">
                  <h6> When do you need it</h6>
                  <input
                    type="date"
                    required
                    min={today}
                    value={data?.whenNeeded}
                    onChange={(e) =>
                      setData({ ...data, whenNeeded: e.target.value })
                    }
                  />
                </div>
                <div className="inputbox-with-one-input-log rounded border mt-5">
                  <label>Loan Term</label>
                  <select
                    value={data.loanTerm}
                    onChange={(e) =>
                      setData({ ...data, loanTerm: e.target.value })
                    }
                  >
                    <option>select loan term</option>
                    <option value="3 months">3 Months</option>
                    <option value="4 months">4 Months</option>
                    <option value="5 months">5 Months</option>
                    <option value="6 months">6 Months</option>
                    <option value="7 months">7 Months</option>
                    <option value="8 months">8 Months</option>
                    <option value="9 months">9 Months</option>
                    <option value="10 months">10 Months</option>
                    <option value="11 months">11 Months</option>
                    <option value="12 months">12 Months</option>
                  </select>
                </div>
              </div>
              <div className="border mt-3">
                <label>Occupation Details</label>
                <div className="inputbox-with-one-input-log text-start grid-cols-2 border">
                  <h6> Occupation type</h6>
                  <input
                    placeholder="Occupation type"
                    type="text"
                    required
                    value={data?.occupationType}
                    onChange={(e) =>
                      setData({ ...data, occupationType: e.target.value })
                    }
                  />
                </div>
                <div className="inputbox-with-one-input-log text-start grid-cols-2 border">
                  <h6> Employer name</h6>
                  <input
                    placeholder="Employer name"
                    type="text"
                    required
                    value={data?.employerName}
                    onChange={(e) =>
                      setData({ ...data, employerName: e.target.value })
                    }
                  />
                </div>
                <div className="inputbox-with-one-input-log text-start grid-cols-2 border">
                  <h6> Employment start date</h6>
                  <input
                    type="date"
                    required
                    value={data?.whenYouJoinedCurrentPlace}
                    onChange={(e) =>
                      setData({
                        ...data,
                        whenYouJoinedCurrentPlace: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="inputbox-with-one-input-log text-start grid-cols-2 border">
                  <h6>Monthly Turnover</h6>
                  <input
                    type="text"
                    required
                    value={data?.salaryAmount}
                    onChange={(e) =>
                      setData({ ...data, salaryAmount: e.target.value })
                    }
                  />
                </div>
              </div>
              <button className="btn btn-primary mb-5" type="submit">
                Next
              </button>
            </form>
          </div>
        ) : tab === 4 ? (
          <div className="col-6">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                setTab(5);
              }}
            >
              <div className="inputbox-with-one-input-log">
                <label> Upload Documents</label>
              </div>
              <div className="border px-2 my-3 rounded">
                <div>Bank statement or Payslip</div>
                <label className="mt- mx-5">Upload file here</label>
                <label htmlFor="fileButton" className="btn btn-primary">
                  Upload File
                </label>
                <input
                  type="file"
                  id="fileButton"
                  ref={inputRef}
                  onChange={(e) => handleFileChange(e, 'statement')}
                  style={{ display: 'none' }}
                />
                {file && <p>Uploaded file: {file.name}</p>}
              </div>
              {/* <div className="border px-2 rounded">
                <div>Government Document</div>
                <label className="mt- mx-5">Upload file here</label>
                <label htmlFor="fileButton" className="btn btn-primary">
                  Upload File
                </label>
                <input
                  type="file"
                  id="fileButton"
                  ref={inputRef}
                  onChange={handleFileChange}
                  style={{ display: 'none' }}
                />
                {file && <p>Uploaded file: {file.name}</p>}
              </div> */}
              {/* <div className="inputbox-with-one-input-log text-start grid-cols-2 border">
                <h6> Govenment Id</h6>
                <input
                  type="text"
                  placeholder="Enter governmentId"
                  value={data.govtId ?? ''}
                  onChange={(e) => setData({ ...data, govtId: e.target.value })}
                />
              </div> */}
              <div className="border px-2 my-3 rounded">
                <div>Government Id</div>
                <label className="mt- mx-5">Upload file here</label>
                <label htmlFor="fileButton2" className="btn btn-primary">
                  Upload File
                </label>
                <input
                  type="file"
                  id="fileButton2"
                  ref={inputRef}
                  onChange={(e) => handleFileChange(e, 'govtId')}
                  style={{ display: 'none' }}
                />
                {file2 && <p>Uploaded file: {file2.name}</p>}
              </div>
              <button className="btn btn-primary my-2 mb-5" type="submit">
                Next
              </button>
            </form>
          </div>
        ) : tab === 5 ? (
          <div className="col-6">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                setTab(6);
              }}
            >
              <div className="inputbox-with-one-input-log">
                <label>Guarantor Details</label>
              </div>
              <div className="inputbox-with-one-input-log border rounded mt-5">
                <label>First Name</label>
                <input
                  placeholder="First Name"
                  type="text"
                  required
                  value={data.gfirstName}
                  onChange={(e) =>
                    setData({ ...data, gfirstName: e.target.value })
                  }
                />
              </div>
              <div className="inputbox-with-one-input-log rounded border">
                <label>Middle Name</label>
                <input
                  placeholder="Middle Name"
                  type="text"
                  value={data.gmiddleName ?? ''}
                  onChange={(e) =>
                    setData({ ...data, gmiddleName: e.target.value })
                  }
                />
              </div>
              <div className="inputbox-with-one-input-log rounded border">
                <label>Last Name</label>
                <input
                  placeholder="Middle Name"
                  type="text"
                  required
                  value={data.glastName}
                  onChange={(e) =>
                    setData({ ...data, glastName: e.target.value })
                  }
                />
              </div>
              <div className="inputbox-with-one-input-log rounded border">
                <label>Date of Birth</label>
                <input
                  type="date"
                  required
                  value={data.gdateOfBirth}
                  onChange={(e) =>
                    setData({ ...data, gdateOfBirth: e.target.value })
                  }
                />
              </div>
              <div className="inputbox-with-one-input-log rounded border mt-5">
                <label>Gender</label>
                <select
                  value={data.ggender}
                  onChange={(e) =>
                    setData({ ...data, ggender: e.target.value })
                  }
                >
                  <option>select gender</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                </select>
              </div>
              <div className="inputbox-with-one-input-log rounded border">
                <label>Address</label>
                <input
                  placeholder="Address"
                  type="text"
                  required
                  value={data.gaddress}
                  onChange={(e) =>
                    setData({ ...data, gaddress: e.target.value })
                  }
                />
              </div>
              <div className="inputbox-with-one-input-log rounded border">
                <label>Email Address</label>
                <input
                  placeholder="Guarantor email"
                  type="email"
                  required
                  value={data.gemailAddress}
                  onChange={(e) =>
                    setData({ ...data, gemailAddress: e.target.value })
                  }
                />
              </div>
              <div className="inputbox-with-one-input-log rounded border">
                <label>Phone Number</label>
                <NumberFormat
                  format="+234###########"
                  allowEmptyFormatting
                  required
                  value={data.gphoneNumber}
                  onChange={(e) =>
                    setData({ ...data, gphoneNumber: e.target.value })
                  }
                />
                {/* <input
                  placeholder="Phone Number"
                  type="text"
                  required
                  value={data.gphoneNumber}
                  onChange={(e) =>
                    setData({ ...data, gphoneNumber: e.target.value })
                  }
                /> */}
              </div>
              {/* <div className="inputbox-with-one-input-log rounded border">
                <label>Government Id</label>
                <input
                  placeholder="Government Id"
                  type="text"
                  required
                  value={data.ggId ?? ''}
                  onChange={(e) => setData({ ...data, ggId: e.target.value })}
                />
              </div> */}
              <div className="border px-2 my-3 rounded">
                <div>Government Id</div>
                <label className="mt- mx-5">Upload file here</label>
                <label htmlFor="fileButton" className="btn btn-primary">
                  Upload File
                </label>
                <input
                  type="file"
                  id="fileButton"
                  ref={inputRef}
                  onChange={(e) => handleFileChange(e, 'ggId')}
                  style={{ display: 'none' }}
                />
                {file3 && <p>Uploaded file: {file3.name}</p>}
              </div>
              <div className="inputbox-with-one-input-log rounded border">
                <label>BVN</label>
                <NumberFormat
                  placeholder="guarantor bvn"
                  format="############"
                  allowEmptyFormatting
                  required
                  value={data.gBvn}
                  onChange={(e) => setData({ ...data, gBvn: e.target.value })}
                />
                {/* <input
                  placeholder="Guarantor BVN"
                  type="text"
                  required
                  value={data.gBvn}
                  onChange={(e) => setData({ ...data, gBvn: e.target.value })}
                /> */}
              </div>
              <div className="inputbox-with-one-input-log rounded border">
                <label>National Identity Number(NIN)</label>
                <NumberFormat
                  placeholder="NIN"
                  format="############"
                  allowEmptyFormatting
                  required
                  value={data.gNin}
                  onChange={(e) => setData({ ...data, gNin: e.target.value })}
                />
                {/* <input
                  placeholder="Guarantor NIN"
                  type="text"
                  required
                  value={data.gNin}
                  onChange={(e) => setData({ ...data, gNin: e.target.value })}
                /> */}
              </div>
              <button className="btn btn-primary my-2 mb-5" type="submit">
                Next
              </button>
            </form>
          </div>
        ) : tab === 6 ? (
          <div className="col-6">
            <form onSubmit={handleSubmitLoan}>
              {/* <div className="inputbox-with-one-input-log border">
                <label>Read and acknowledgement</label>
              </div> */}
              <div className="inputbox-with-one-input-log rounded border mt-5">
                <label>Select Repayment Account</label>
                <select
                  required
                  onChange={(e) => {
                    const { bankAccountNumber, bankName } = JSON.parse(
                      e.target.value
                    );
                    setData({
                      ...data,
                      repayAccount: bankAccountNumber,
                      repayBank: bankName,
                    });
                  }}
                >
                  <option>select repayment account</option>
                  {data?.activeBankAccountsDtos?.map((e) => (
                    <option value={JSON.stringify(e)} key={e.account_number}>
                      {`${e?.bankName} - ${e?.bankAccountNumber}`}
                    </option>
                  ))}
                </select>
              </div>
              <div className='form-group'>
                <input type='checkbox' id='term1' checked={terms} onChange={(e) => setTerms(e.target.checked)} />{' '}
                <span className='add-cursor'>
                  By submitting, you agree with the{' '}
                  <span
                    className='primary-link'
                    onClick={() => {
                      // setShowTerms(true);
                      history.push('/terms')
                    }}
                  >
                    terms and conditions
                  </span>{' '}
                </span>
              </div>
              <button className="btn btn-primary my-5" type="submit">
                Submit
              </button>
            </form>
          </div>
        ) : (
          ''
        )}
      </div>
      {showOtpModal ? (
        <PinModal
          center
          showModal={showOtpModal}
          hideModal={setShowOtpModal}
          otp={otp}
          setOtp={setOtp}
          numInputs={6}
          separator=""
          buttonLabel="Verify"
          title="Verify Your Details"
          description="Please input the OTP sent to your Phone"
          handleSubmit={handleOtp}
          // setModalAction={setModalAction}
          handleResendOtpto={handleSubmit}
          // isResendOtp
        />
      ) : (
        ''
      )}
      {showActOpt ? (
        <AccountListModal
          methods={actList}
          showModal={showActOpt}
          hideModal={setShowActOpt}
          verificationMethod={verificationMethod}
          setVerificationMethod={setVerificationMethod}
          handleContinue={handleGetDetails}
        />
      ) : (
        ''
      )}
    </Layout>
  );
}
