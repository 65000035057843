import React, { useEffect, useState } from "react";
import { Tabs } from "../Wayapay/trasactionCard";
import Layout from '../../shared/Layout';
import TransactionTable from "../../shared/Table/transaction";
import CreateDispute from "../Wayagram/Dispute/CreateDispute";
import { Add } from "@mui/icons-material";
import useUser from "../../../pos/components/Hooks/useUser";
import axios from "axios";
import moment from "moment";
import { customBaseUrl } from "../../../action/http";
import envConfig from "../../../utils/envConfig";
import { hideLoader, showLoader } from "../../../utils/loader";
import { apiCall } from "../../../pos/utilities/apis";
import { allTerminals } from "../../../pos/plugins/urls";

export const Dispute = () => {
    const userObj = useUser() || {};
    const { user } = JSON.parse(localStorage.getItem("userDetails"));
    const merchantId = userObj?.posUserProfile?.merchantId ?? "";
    const [tab, setTab] = useState(1);
    const [filtered, setFiltered] = useState([]);
    const [notifications, setNotifications] = useState([]);
    const [showCreate, setShowCreate] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [terminals, setTerminals] = useState([]);

    const DisputesTab = ['My Dispute', 'Dispute Notification'];
    const grantType = envConfig.disputeGrantType;
    const clientId = envConfig.disputeClientId;
    const secretId = envConfig.disputeClientSecret;
  
    const handleInit = async () => {
      try{
        showLoader();
        const authData = new FormData();
          authData.append('grant_type', grantType);
          authData.append('client_id', clientId);
          authData.append('client_secret', secretId)
        const authRes = await axios.post(`${customBaseUrl.disputeUrlV2}/api/oauth2/token`,
          authData,
          {
            headers: {
              Authorization: '',
            }
          }
        );
    
       //http://35.178.153.136:8000/api/merchants/MERCHANT003/disputes?status=RESOLVED&type=POS&page=1&size=10
        const res = await axios.get(`${customBaseUrl.disputeUrlV2}/api/merchants/${merchantId}/disputes`,
          {
            headers: {
              Authorization: `${authRes.data.token_type} ${authRes.data.access_token}`,
              secret_id: secretId,
              client_id: clientId
            }
          }
        );
        const res2 = await axios.get(`${customBaseUrl.disputeUrlV2}/api/disputes/notifications`,
            {
              headers: {
                Authorization: `${authRes.data.token_type} ${authRes.data.access_token}`,
                secret_id: secretId,
                client_id: clientId
              }
            }
          );
        if (res.data) {
            setFiltered(res.data.data)
        //   swal('Success', res.message).then(() => {            
        //   });
        }
        if (res2.data) {
          setNotifications(res2.data)
      //   swal('Success', res.message).then(() => {            
      //   });
      }
      hideLoader();
    }catch(err){
      console.log(err)
      hideLoader();
    }
      };

       const userTerminals = () =>
          apiCall({
            url: `${allTerminals}`,
            data: {
              pageSize: 100,
              pageNo: 1,
              paramList: [
                {
                  userid: user?.id,
                },
              ],
            },
            defaultMeta: true,
            // setLoading: setLoadingWal,
            success: (data) => {
              setTerminals(data.content);
              // setTeminalNo(data?.content[0]?.accountNo);
            },
            method: "post",
          });

      useEffect(() => {
        handleInit();
        userTerminals();
      }, [refresh]);
    return(
      <Layout>
        <div className="mx-2 my-2" style={{ textAlign: 'end'}}>
            <button className="btn btn-primary" onClick={() => setShowCreate(true)}>
                <Add />
                Raise new Dispute
            </button>
            <Tabs data={DisputesTab} tab={tab} setTab={setTab} />
        </div>
        <div>
            {tab === 1 ? (
              <TransactionTable
                // hideModal={hideModal}
                options={{
                    exportButton: false,
                    search: false,
                    sorting: false,
                    paging: false,
                    headerStyle: {
                    backgroundColor: '#FF6700',
                    color: '#ffffff',
                    whiteSpace: 'nowrap',
                    zIndex: 0,
                    },
                }}
                columns={[
                    { title: 'Dispute id', field: 'id' },
                    { title: 'Merchant Id', field: 'merchant_id' },
                    { title: 'Dispute Category', field: 'category' },
                    { title: 'Bill Category', field: 'biller_category' },
                    { title: 'Biller Name', field: 'biller' },
                    { title: 'log Date', field: 'log_date' },
                    { title: 'Status', field: 'status' },
                    { title: 'Resolution Reference', field: 'reference' },
                    { title: "Resolution Date", field: 'resolution_date' },
                    // { title: 'Status', field: 'status' },
                    { title: 'Remark', field: 'status' },
                ]}
                data={
                    filtered?.length > 0 &&
                    filtered?.map((el) => {
                    return {
                        id: el.id,
                        category: el.type,
                        biller_category: el?.bill_payment_dispute?.bill_category ?? 'N/A',
                        biller: el?.bill_payment_dispute?.biller_name ?? 'N/A',
                        senderName: el.senderName,
                        merchant_id: el.merchant_id,
                        //  acctNum: el.acctNum,
                        log_date: moment(el.log_date).format('dd-mm-yyyy'),
                        status: el.status,
                        resolution_date: el.resolution_date ? moment(el.resolution_date).format('dd-mm-yyyy') : 'N/A',
                        createdAt: el.createdAt,                        
                    };
                    })
                }
                />
            ) : (
              <TransactionTable
                // hideModal={hideModal}
                options={{
                    exportButton: false,
                    search: false,
                    sorting: false,
                    paging: false,
                    headerStyle: {
                    backgroundColor: '#FF6700',
                    color: '#ffffff',
                    whiteSpace: 'nowrap',
                    zIndex: 0,
                    },
                }}
                columns={[
                    { title: 'ID', field: 'id' },
                    { title: 'Transaction Id', field: 'transaction_id' },
                    { title: 'Merchant Id', field: 'merchant_id' },
                    { title: 'Dispute Category', field: 'category' },
                    { title: 'log Date', field: 'log_date' },
                    { title: 'Transaction Date', field: 'trans_date' },
                    { title: 'Status', field: 'status' },
                    { title: "Resolution Date", field: 'resolution_date' },
                    // { title: 'Status', field: 'status' },
                    { title: 'Remark', field: 'status' },
                ]}
                data={
                    notifications?.length > 0 &&
                    notifications?.map((el) => {
                    return {
                        id: el.id,
                        category: el.type,
                        senderName: el.senderName,
                        transaction_id: el.transaction_id,
                        merchant_id: el.merchant_id,
                        //  acctNum: el.acctNum,
                        log_date: moment(el.log_date).format('dd-mm-yyyy'),
                        trans_date: moment(el.trans_date).format('dd-mm-yyyy'),
                        status: el.status,
                        resolution_date: el.resolution_date ? moment(el.resolution_date).format('dd-mm-yyyy') : 'N/A',
                        createdAt: el.createdAt,                        
                    };
                    })
                }
                />
            )}
        </div>
        {showCreate ? (
            <CreateDispute terminals={terminals} setRefresh={setRefresh} refresh={refresh} showModal={showCreate} hideModal={setShowCreate} />
        ) : (
            ''
        )}
      </Layout>
    )
}